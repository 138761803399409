function phone_warning(form) {

    $(form).submit(function(e) {
        var message = 'For security purposes we need you to verify your mobile phone number in order to create an account. <br/><br/> A 7 digit code will be sent to ' + $('#phone_prefix').text() + ' ' + $('input[name=phone_number]').val() + ' via text message. <br/><br/> Click OK to continue or Back to re-enter your phone number.';
        var self = this;

        if (!$(form).valid()) {
            return false;
        }

        $.confirm({
            text: message,
            confirmButton: "OK",
            cancelButton: "Back",
            confirm: function() {
                self.submit();
            },
            cancel: function() {
                // nothing to do
            }
        });
        e.preventDefault();
        return false;
        //return confirm(message);
    });
}

function stickyMenu() {
    var windowHeight = $(window).height();
    var mainNav = $('#mainNav');
    var USPsWrapper =$('#USPs-wrapper')
    var isHomePage = $('#section-one-home').length > 0;

    $(window).scroll(function() {
        var topFooterOffset = $('footer').offset().top - windowHeight;
        var scrollOffset = $(this).scrollTop();
        
        if (1!=1 && isHomePage) {
            var topMenuOffsetHome = $('#section-one-home').offset().top - 90;

            if (scrollOffset > topMenuOffsetHome) {
                mainNav.addClass('fixed').removeAttr('style');
            } else {
                mainNav.removeClass('fixed');
            }
        } else {
            var topMenuOffset = $('.sticky-nav-wrapper').offset().top;
            if($('#edit-villa').length > 0) {
                mainNav.addClass('has-dashboard-btn');
            }

            if (scrollOffset > (topMenuOffset + 30)) {
                mainNav.addClass('fixed');
            } else {
                mainNav.removeClass('fixed');
            }
        }


        if (scrollOffset > topFooterOffset) {
            USPsWrapper.removeClass('fixed');
        } else if ((scrollOffset < windowHeight - 90) && ($('body').hasClass('home-page'))){
            USPsWrapper.removeClass('fixed');
        } else {
            USPsWrapper.addClass('fixed');
        }
    });

    if(!isHomePage) {
        USPsWrapper.addClass('fixed');
    }

    $("#USPs-toggler").click(function() {
        $(this).parent().toggleClass('vissible');
    });
}

$(document).ready(function(){
    
    $('body').on('click', function(){
        $(this).removeClass('tvc_show_menu')
    })

    $('.tvc_wrapper_menu_pc .tvc_content_header .navbar-toggle').on('click', function(e){
        e.stopPropagation();
        if ( $('body').hasClass('tvc_show_menu') ) {
            $('body').removeClass('tvc_show_menu')
            $( this ).addClass('collapsed')
        }
        else {
            $('body').addClass('tvc_show_menu')
            $( this ).removeClass('collapsed')
        }
    })

    $('.tvc_wrapper_menu_mb').on('click', function(e){
        e.stopPropagation();
    })

    $('.tvc_wrapper_menu_mb .tvc_content_header .navbar-toggle').on('click', function(e){
        e.stopPropagation();
        $('body').removeClass('tvc_show_menu')
    })

    if( $(window).width() <= 1024 ) {
        $('.tvc_wrapper_menu_mb .btn-enquiry').on('click', function(e){
            $('body').addClass('show_modal_enquiry_moblie').removeClass('tvc_show_menu')
        })
    
        $('.tvc_close_modal_enquiry').on('click', function() {
            $('body').removeClass('show_modal_enquiry_moblie')
        })
    }
});
