(function() {
    var newsletterFooterForm = $('form.newsletter-form');
    newsletterFooterForm.on('submit', function(e) {
        
        e.preventDefault();

        function callbackPopup(message, success) {
            success = typeof success != 'undefined' ? success : false;
            swal.fire({
                title: 'An error occured',
                text: message,
                icon: 'warning',
                showCancelButton: !success,
                confirmButtonColor: '#7091A7',
                cancelButtonColor: '#7091A7',
                confirmButtonText: 'OK',
                cancelButtonText: 'Back',
            }).then( function(result){
                console.log( 'result', result )
            })
        }

        if (newsletterFooterForm.valid()) {
            $.ajax({
                url: $(this).attr('action'),
                method: 'POST',
                data: $(this).serialize(),
                dataType: 'json',
                success: function(data) {
                    callbackPopup(data.message);
                    localStorage.setItem("newsletterBarHidden", "1");
                    $('.pop-up-bar').remove();
                    $('form.newsletter-form').each(function(){
                        this.reset();
                    });
                },
                error: function(response, t) {
                    callbackPopup(response.message, false);
                }
            });
        }
    });
})();